import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello Everyone! My name is <span className="purple">Lijah Babu Gongal. </span>
            <br/>
            I am from <a href="https://en.wikipedia.org/wiki/Bhaktapur" target="_blank" rel="noopener noreferrer" style={{ color: "#c770f0" }}>Bhaktapur, Nepal.</a> 🇳🇵
            <br />
            I am currently working as a <span className="purple"> Backend Developer.</span> 👨🏻‍💻
            <br />
            I have completed my <span className="purple">Bachelor of Technology in Computer Science & Engineering   </span> from <a href="https://vit.ac.in/" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>VIT Vellore, Tamil-Nadu, India. </a> 👨🏻‍🎓
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing football ⚽️
            </li>
            <li className="about-activity">
              <ImPointRight /> Painting & sketch 🎨
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling new places 🗺️
            </li>
            <li className="about-activity">
              <ImPointRight /> Enjoying cultural fests 🎊
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring different foods 🍜
            </li>
            <li className="about-activity">
              <ImPointRight /> Capturing beautiful pictures 📸
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;