import React from "react";
import Card from "react-bootstrap/Card";

function CertificationsCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Body>
      <Card.Img variant="top" src={props.imgPath} alt="card-img"/>
        <Card.Title>{props.title}</Card.Title>
        {"\n"}
        {"\n"}
      </Card.Body>
    </Card>
  );
}
export default CertificationsCards;