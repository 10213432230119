import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I’m a passionate
            <i>
                <b className="purple"> Computer Science & Engineering Undergraduate      {"  "}</b>
              </i>
              with a love for programming, particularly in backend development. I thrive on the challenge of designing efficient, scalable, and reliable systems that make a real impact.
              <br />
              <br />
              What excites me the most is creating backend systems that aren’t just functional but are also highly optimized for performance and seamless integration. I enjoy turning complex ideas into powerful, well-crafted solutions that drive innovation.              <br />
              <br />
              In my free time, I love experimenting with             
              <i>
                <b className="purple">
                  {" "}
                  Modern Python Libraries and Frameworks,
                  {" "}
                </b>
              </i>
              channeling my creativity into building products that solve real-world problems. 
            </p>
          </Col>
          <Col md={4} className="myAvtar"style={{ paddingTop: "1px", paddingBottom: "10px" , zIndex: 5,}}>
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>YOU CAN FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/leejawww"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/lijah-gongal-622b8a224/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/i_am_leejaw/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
            <p style={{ fontSize: "20px" }}>
              Feel free to <span className="purple">connect </span>with me
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;