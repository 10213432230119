import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import capstone from "../../Assets/Projects/capstone.png";
import vehicle from "../../Assets/Projects/vehicle.png";
import twitter from "../../Assets/Projects/twitter.jpeg";
import movie from "../../Assets/Projects/movie.png";
import complaint from "../../Assets/Projects/complaint.png";
import traffic from "../../Assets/Projects/traffic.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are few projects I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={capstone}
              isBlog={false}
              title="Comprehensive Project Management Tool"
              description="Project Goal: To modernize project management practices using blockchain for secure, tamper-proof data and AI/ML for predictive analytics. The solution aims to enhance governance, streamline processes, reduce corruption, and build public trust, particularly for government teams."
              description2="Technology Used: Django, PostgreSQL, Blockchain technology(Ganache), Smart contracts for automating project workflows."
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={movie}
              isBlog={false}
              title="Movie Recommender System Using Web Mining Techniques"
              description="Project Goal: Develop a movie recommendation system using web mining techniques and machine learning to provide personalized recommendations while addressing the cold-start problem for new users and movies."
              description2="Technology Used: Web Mining Techniques, Data Preprocessing, Machine Learning Algorithms(Collaborative Filtering, Content-Based Filtering)"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={traffic}
              isBlog={false}
              title="Network Traffic Analysis and Prediction using Machine Learning"
              description="Project Goal: To analyze and predict network traffic patterns using machine learning techniques, helping network administrators identify potential issues, optimize performance, and enhance security by applying clustering, anomaly detection, time series forecasting, and regression analysis."
              description2="Technology Used: Machine Learning Algorithms, Data Processing Tools(Pandas, NumPy), Network Monitoring Tools(Wireshark)"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={twitter}
              isBlog={false}
              title="Twitter Hate Speech Detection Using DistilBERT Algorithm"
              description="Project Goal: Develop a smaller, efficient language representation model with high performance and reduced computational resource requirements."
              description2="Technology Used: Python, Recurrent Neural Networks (RNN), Long Short-Term Memory (LSTM), DistilBERT (a smaller, pre-trained language model)"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={complaint}
              isBlog={false}
              title="An Online Complaint Management System for VIT"
              description="Project Goal: Develop an Online Complaint Management System that streamlines the process of handling and resolving user complaints, improves user satisfaction, and enhances performance by providing an efficient way to track, analyze, and respond to issues while ensuring privacy and security."
              description2="Technology Used: HTML, CSS, JavaScipt, PHP, MySQL"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vehicle}
              isBlog={false}
              title="Vehicle Accident Detection System using GPS & GSM"
              description="Project Goal: To develop a real-time Vehicle Accident Detection System that automatically detects accidents, retrieves the vehicle's location via GPS, and sends instant notifications to pre-set phone numbers using GSM technology, ensuring rapid emergency response and enhanced safety."
              description2="Technology Used: GSM and GPS."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;