import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./CertificationsCard";
import Particle from "../Particle";
import gcloud from "../../Assets/Certifications/googlecloud.jpg";
import gcoursera from "../../Assets/Certifications/googleCoursera.jpg";
import gFE from "../../Assets/Certifications/googleFE.jpg";
import microsoft from "../../Assets/Certifications/microsoft.jpg";
import microFE from "../../Assets/Certifications/microsoftFE.jpg";

function Certifications() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        <strong className="purple">Certificates </strong> Achieved
        </h1>
        <p style={{ color: "white" }}>
          These are some certificates I've achieved.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px"}}>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={microsoft}
              isBlog={false}
              title="Security, Compliance & Identity Fundamentals (MICROSOFT)"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={gcoursera}
              isBlog={false}
              title="Google Data Analytics Capstone (COURSERA)"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={gcloud}
              isBlog={false}
              title="Google Cloud Generative AI (NASSCOM)"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={microFE}
              isBlog={false}
              title="Front-End Web Development Essentials (MICROSOFT LEARN)"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={gFE}
              isBlog={false}
              title="Front-End Web Development Essentials (GDSC)"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Certifications;